<template lang="pug">
  div
    v-snackbar(:timeout='5000' top='' right='' :color='snackbar.color' v-model='snackbar.show')
      | {{ snackbar.text }}
      v-btn(dark='' text='' @click.native='snackbar.show = false' icon='' style='background: transparent;')
        v-icon mdi-close
    v-dialog(fullscreen="" v-model='dialodProveedor')
      v-card
        registro-proveedor-modal(@closeModal="closeModalProvider" :providerToEdit='providerToEdit')
    v-dialog(fullscreen="" v-model='dialodProveedorExterno')
      v-card
        registro-proveedor-externo-modal(@closeModal="closeModalExterno" :providerToEdit='providerToEditExterno')
    v-dialog(v-model='dialogEvent' width='500' v-if="selectEvent.name" persistent)
      v-card
        v-card-title.headline.primary.lighten-2(primary-title='' style="color: white; font-weight: bold;")
          | CITA
        v-card-text
          v-row(style="padding: 0px; margin: 0px;")
            v-col(cols='12' md='12')
              v-text-field.purple-input(disabled v-model='selectEvent.pvd' label='Proveedor' type="text")
            v-col(cols='12' md='12')
              v-text-field.purple-input(disabled v-model='selectEvent.tlf' label='Teléfono del proveedor' type="text")
            v-col(cols='12' md='12')
              v-text-field.purple-input(disabled v-model='selectEvent.dir' label='Dirección del proveedor' type="text")
            v-col(cols="12" md="12" style='padding:0px;')
              v-textarea(solo='' name='input-7-4' label='Motivo de Agendamiento' height="70px" v-model="selectEvent.obs" disabled)
            v-col(cols="12" md="12" style='padding:0px;')
              v-textarea(solo='' name='input-7-4' label='Camión Utilizado' height="70px" v-model="selectEvent.pro" disabled)
            v-col(cols="12" md="6" style='padding:0px;')
              v-menu(v-model='startMenu1' :close-on-content-click='false' :nudge-right='40' transition='scale-transition' offset-y='' min-width='290px')
                template(v-slot:activator='{ on, attrs }')
                  v-text-field(disabled v-model='selectEvent.start' label='Inicio' prepend-icon='mdi-calendar' readonly='' v-bind='attrs' v-on='on')
                v-date-picker(v-model='selectEvent.start' no-title @input='startMenu1 = false' style='margin:0px;')
            v-col(cols="12" md="6" style='padding:0px;')
              v-menu(v-model='endMenu' :close-on-content-click='false' :nudge-right='40' transition='scale-transition' offset-y='' min-width='290px')
                template(v-slot:activator='{ on, attrs }')
                  v-text-field(disabled v-model='selectEvent.end' label='Fin' prepend-icon='mdi-calendar' readonly='' v-bind='attrs' v-on='on')
                v-date-picker(v-model='selectEvent.end' no-title @input='endMenu = false' style='margin:0px;')
        v-divider
        v-card-actions
          v-spacer
          v-btn(color='primary' @click='closeEvent()')
            | Salir
          v-btn(color='red' text='' @click='cancelAppoiment')
            | Cancelar Cita
    v-row(justify='center')
      v-col(cols='12' md='12' style="background-color: white; margin-top: 20px;")
        v-dialog(v-model='dialogAppointment' width='500' persistent)
          v-card
            v-card-title.headline.primary.lighten-2(primary-title='' style="color: white; font-weight: bold;")
              | AGENDAR CITA
            v-card-text
              v-form(ref='formAddEvent' v-model='validAddEvent' lazy-validation='' style="padding-top: 10px;")
                v-row(style="margin-bottom: 5px;")
                  v-col.py-0(cols="10" md="11")
                    v-autocomplete(dense="" :rules='[rules.required]' v-model='provider' :items='arrayProveedores' return-object='return-object' item-text='completeData' label='Proveedor Interno')
                  v-col.py-0.pl-0.ml-0(cols="2" md="1" style="text-align: center;")
                    v-tooltip(bottom="")
                      template(v-slot:activator="{ on }")
                        v-btn(v-on="on" icon text color="purple darken-3" @click="dialodProveedor = true" style='background:#B18BE2;')
                          v-icon(style="font-size: 25px;") mdi-plus
                      span.tooltips Crear Proveedor
                  v-col.py-0(cols="10" md="11")
                    v-autocomplete(dense="" :rules='[rules.required]' v-model='provider' :items='arrayProveedoresExternos' return-object='return-object' item-text='completeData' label='Proveedor Externo')
                  v-col.py-0.pl-0.ml-0(cols="2" md="1" style="text-align: center;")
                    v-tooltip(bottom="")
                      template(v-slot:activator="{ on }")
                        v-btn(v-on="on" icon text color="purple darken-3" @click="dialodProveedorExterno = true" style='background:#B18BE2;')
                          v-icon(style="font-size: 25px;") mdi-plus
                      span.tooltips Crear Proveedor Externo
                  v-col(cols='12' md='12' v-if="provider")
                    v-text-field.purple-input(readonly v-model='provider.phone' label='Teléfono del proveedor' type="text")
                  v-col(cols='12' md='12' v-if="provider")
                    v-text-field.purple-input(v-model='provider.direction' label='Dirección del proveedor' type="text")
                v-menu(v-model='nowMenu' :close-on-content-click='false' :nudge-right='40' transition='scale-transition' offset-y='' min-width='290px')
                  template(v-slot:activator='{ on, attrs }')
                    v-text-field(v-model='now' label='seleccionar Fecha' prepend-icon='mdi-calendar' readonly='' v-bind='attrs' v-on='on')
                  v-date-picker(v-model='now' no-title @input='nowMenu = false' style='margin:0px;')
                v-row(style="padding: 0px; margin: 0px;")
                  v-col(cols="12" md="6" style="padding: 0px; margin: 0px;")
                    v-text-field(v-model='time' label='Hora Desde' prepend-icon='mdi-timetable' type="time")
                  v-col(cols="12" md="6" style="padding: 0px; margin: 0px;")
                    v-text-field(v-model='time2' label='Hora Hasta' prepend-icon='mdi-timetable' type="time")
                v-textarea(@keyup="scheduleReason = scheduleReason.toString().toUpperCase()" :rules='[rules.required]' solo='' name='input-7-4' label='Motivo de Agendamiento' height="60px" v-model="scheduleReason")
                v-textarea(@keyup="usedTruck = usedTruck.toString().toUpperCase()" :rules='[rules.required]' solo='' name='input-7-4' label='Camión Utilizado' height="60px" v-model="usedTruck")
                v-row
                  v-col(cols="6")
                    v-btn.mr-0(color='red' @click='dialogAppointment = false' style="width: 100%;")
                      | SALIR
                  v-col(cols="6")
                    v-btn.mr-0(color='primary' @click='addEvent' style="width: 100%;" :loading="loadingAgendar")
                      | AGENDAR CITA
        v-col.pl-4(cols='12' md='12')
          v-row
            v-col(cols="4" md="3" style="padding-top: 20px; padding-bottom:0;")
              v-checkbox(@change="changeAll" v-model='allAppoitment' :label='`Ver todas las citas`' style='margin:0px;')
            v-col(cols="3" md="1" style="text-align: center;")
              v-btn(fab='' small='' left='' color='primary' @click='$refs.calendar.prev()')
                v-icon(dark='') mdi-chevron-left
            v-col(cols="6" md="3" style="padding-top: 5px;")
              v-menu(v-model='startMenu' :close-on-content-click='false' :nudge-right='40' transition='scale-transition' offset-y='' min-width='290px')
                template(v-slot:activator='{ on, attrs }')
                  v-text-field(v-model='start' label='Fecha de la Cita' prepend-icon='mdi-calendar' readonly='' v-bind='attrs' v-on='on')
                v-date-picker(v-model='start' no-title @input='startMenu = false' style='margin:0px;')
            v-col(cols="3" md="1" style="text-align: center; padding:12px 0px;")
              v-btn(fab='' small='' right='' color='primary' @click='$refs.calendar.next()')
                v-icon(dark='') mdi-chevron-right
            v-col(cols='12' md="4")
              v-btn.mr-0(color="primary" @click='openAppointment' style="width: 100%;")
                | AGENDAR CITA
        v-col.pl-4(cols='12' md='12')
          v-row
            v-col(cols='12' md="3" style='padding-top:0;')
              v-select(v-model='type' :items='typeOptions' label='Ver' hide-details='' outlined='' dense='')
            v-col(cols="12" md="3" style='padding-top:0;')
              v-menu(ref='menuFrom' v-model='menuFrom' :close-on-content-click='false' :return-value.sync='dateFrom' transition='scale-transition' offset-y='' min-width='290px')
                template(v-slot:activator='{ on }')
                  v-text-field(readonly v-model='dateFrom' label='Buscar Desde' prepend-icon='mdi-calendar' v-on='on')
                v-date-picker(@change='$refs.menuFrom.save(dateFrom)' v-model='dateFrom' no-title='' scrollable='' :max="dateUntil" style='margin:0px;' color="green lighten-1" :min="minDateForConsults")
            v-col(cols="12" md="3" style='padding-top:0;')
              v-menu(ref='menuUntil' v-model='menuUntil' :close-on-content-click='false' :return-value.sync='dateUntil' transition='scale-transition' offset-y='' min-width='290px')
                template(v-slot:activator='{ on }')
                  v-text-field(readonly v-model='dateUntil' label='Buscar Hasta' prepend-icon='mdi-calendar' v-on='on')
                v-date-picker(@change='$refs.menuUntil.save(dateUntil)' v-model='dateUntil' no-title='' scrollable='' :min="dateFrom" style='margin:0px;' color="green lighten-1")
            v-col(cols='12' md="3" style='padding-top:0;')
              v-btn.mr-0(color="secondary" @click='downloadDataExcel' style="width: 100%;")
                | DESCARGAR DATOS
          v-sheet(height='600')
            v-calendar(@click:more="moreEvent($event)" @click:day="dayFunction($event)" @click:event="eventFunction($event)" locale="es" ref='calendar' v-model='start' :type='type' :start='start' :end='end' :min-weeks='minWeeks' :max-days='maxDays' :now='now' :dark='true' :weekdays='weekdays' :first-interval='intervals.first' :interval-minutes='intervals.minutes' :interval-count='intervals.count' :interval-height='intervals.height' :interval-style='intervalStyle' :show-interval-label='showIntervalLabel' :short-intervals='shortIntervals' :short-months='shortMonths' :short-weekdays='shortWeekdays' :color='color' :events='events' :event-overlap-mode='mode' :event-overlap-threshold='45' :event-color='getEventColor' :event-more="true")
</template>

<script>
  // import firebase from 'firebase'
  import firebase from 'firebase/app'
  import 'firebase/auth'
  import 'firebase/database'
  import moment from 'moment'
  import * as functions from '../../../functions.js'
  const weekdaysDefault = [0, 1, 2, 3, 4, 5, 6]

  const intervalsDefault = {
    first: 0,
    minutes: 60,
    count: 24,
    height: 48,
  }

  const stylings = {
    default (interval) {
      return undefined
    },
    workday (interval) {
      const inactive = interval.weekday === 0 ||
        interval.weekday === 6 ||
        interval.hour < 9 ||
        interval.hour >= 17
      const startOfHour = interval.minute === 0
      const dark = this.dark
      const mid = dark ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'

      return {
        backgroundColor: inactive ? (dark ? 'rgba(0,0,0,0.4)' : 'rgba(0,0,0,0.05)') : undefined,
        borderTop: startOfHour ? undefined : '1px dashed ' + mid,
      }
    },
    past (interval) {
      return {
        backgroundColor: interval.past ? (this.dark ? 'rgba(0,0,0,0.4)' : 'rgba(0,0,0,0.05)') : undefined,
      }
    },
  }
  export default {
    components: {
      RegistroProveedorModal: () => import('@/views/dashboard/component/application/RegistroProveedorModal'),
      RegistroProveedorExternoModal: () => import('@/views/dashboard/component/application/RegistroProveedorExternoModal'),
    },
    data: () => ({
      minDateForConsults: moment().subtract(15, 'days').format('YYYY-MM-DD'),
      dialodProveedorExterno: false,
      providerToEditExterno: {
        name: '',
        mail: '',
        personType: ['Natural'],
        ruc: '',
        telephone: '',
        phone: '',
        description: '',
        providerKey: '',
        direction: '',
        guideFrom: '',
        guideUntil: '',
        external: true,
      },
      dialodProveedor: false,
      providerToEdit: {
        name: '',
        mail: '',
        personType: ['Natural'],
        ruc: '',
        telephone: '',
        phone: '',
        description: '',
        providerKey: '',
      },
      provider: null,
      menuUntil: false,
      menuFrom: false,
      dateFrom: moment(new Date()).format('YYYY-MM-DD'),
      dateUntil: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().substr(0, 10),
      rules: {
        required: value => !!value || 'Requerido',
        min: v => v.length >= 6 || 'Mínimo 6 caracteres',
      },
      validAddEvent: true,
      loadingAgendar: false,
      allAppoitment: true,
      banEventSelect: false,
      scheduleReason: '',
      usedTruck: '',
      snackbar: {
        show: false,
        text: '',
        color: '',
      },
      dialogAppointment: false,
      selectEvent: {},
      dialogEvent: false,
      time: null,
      time2: null,
      menuTime: false,
      menuTime2: false,
      date: new Date().toString().substr(4, 12),
      menu: false,
      dark: false,
      startMenu: false,
      start: '2019-01-27',
      endMenu: false,
      end: '2019-01-27',
      nowMenu: false,
      startMenu1: false,
      minWeeks: 1,
      now: null,
      events: [],
      eventsComplete: [],
      colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
      names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
      type: 'month',
      typeOptions: [
        { text: 'Todo el día', value: 'day' },
        { text: 'Toda la semana', value: 'week' },
        { text: 'Todo el mes', value: 'month' },
      ],
      mode: 'stack',
      modeOptions: [
        { text: 'Stack', value: 'stack' },
        { text: 'Column', value: 'column' },
      ],
      weekdays: weekdaysDefault,
      weekdaysOptions: [
        { text: 'Sunday - Saturday', value: weekdaysDefault },
        { text: 'Mon, Wed, Fri', value: [1, 3, 5] },
        { text: 'Mon - Fri', value: [1, 2, 3, 4, 5] },
        { text: 'Mon - Sun', value: [1, 2, 3, 4, 5, 6, 0] },
      ],
      intervals: intervalsDefault,
      intervalsOptions: [
        { text: 'Normal', value: intervalsDefault },
        { text: 'Oficina', value: { first: 16, minutes: 30, count: 20, height: 48 } },
      ],
      maxDays: 7,
      maxDaysOptions: [
        { text: '7 days', value: 7 },
        { text: '5 days', value: 5 },
        { text: '4 days', value: 4 },
        { text: '3 days', value: 3 },
      ],
      styleInterval: 'default',
      styleIntervalOptions: [
        { text: 'Default', value: 'default' },
        { text: 'Workday', value: 'workday' },
        { text: 'Past', value: 'past' },
      ],
      color: 'primary',
      colorOptions: [
        { text: 'Primary', value: 'primary' },
        { text: 'Secondary', value: 'secondary' },
        { text: 'Accent', value: 'accent' },
        { text: 'Red', value: 'red' },
        { text: 'Pink', value: 'pink' },
        { text: 'Purple', value: 'purple' },
        { text: 'Deep Purple', value: 'deep-purple' },
        { text: 'Indigo', value: 'indigo' },
        { text: 'Blue', value: 'blue' },
        { text: 'Light Blue', value: 'light-blue' },
        { text: 'Cyan', value: 'cyan' },
        { text: 'Teal', value: 'teal' },
        { text: 'Green', value: 'green' },
        { text: 'Light Green', value: 'light-green' },
        { text: 'Lime', value: 'lime' },
        { text: 'Yellow', value: 'yellow' },
        { text: 'Amber', value: 'amber' },
        { text: 'Orange', value: 'orange' },
        { text: 'Deep Orange', value: 'deep-orange' },
        { text: 'Brown', value: 'brown' },
        { text: 'Blue Gray', value: 'blue-gray' },
        { text: 'Gray', value: 'gray' },
        { text: 'Black', value: 'black' },
      ],
      shortIntervals: false,
      shortMonths: false,
      shortWeekdays: false,
    }),
    computed: {
      plan () {
        return this.$store.state.suscriptionPlan.plan
      },
      intervalStyle () {
        return stylings[this.styleInterval].bind(this)
      },
      hasIntervals () {
        return this.type in {
          week: 1, day: 1, '4day': 1, 'custom-daily': 1,
        }
      },
      hasEnd () {
        return this.type in {
          'custom-weekly': 1, 'custom-daily': 1,
        }
      },
      lstAgenda () {
        return this.$store.state.agenda.lstAgenda
      },
      arrayProveedores () {
        return this.$store.state.provider.lstProviders
      },
      arrayProveedoresExternos () {
        return this.$store.state.provider.lstProvidersExternos
      },
    },
    watch: {
      plan () {
        if (this.plan.diasConsulta) {
          this.minDateForConsults = moment().subtract(this.plan.diasConsulta, 'days').format('YYYY-MM-DD')
        }
      },
      lstAgenda () {
        this.getEvents()
      },
    },
    created () {
      if (this.detectMob() === true) {
        this.type = 'day'
      } else {
        this.type = 'month'
      }
      this.getEvents()
      this.$store.dispatch('getCurrentDay').then(res => {
        this.start = res
        this.now = res
      })
      this.$store.dispatch('getCurrentTime').then(res => {
        this.time = res
      })
      this.$store.dispatch('getCurrentTimeInterval', 1).then(res => {
        this.time2 = res
      })
    },
    methods: {
      async closeModalExterno () {
        this.dialodProveedorExterno = false
      },
      addNewProviderExterno () {
        this.dialodProveedorExterno = true
        this.providerToEditExterno = {
          name: '',
          mail: '',
          personType: ['Natural'],
          ruc: '',
          telephone: '',
          phone: '',
          description: '',
          providerKey: '',
          direction: '',
          guideFrom: '',
          guideUntil: '',
          external: true,
        }
      },
      closeModalProvider () {
        this.dialodProveedor = false
      },
      downloadDataExcel () {
        const data = {
          firstDay: this.dateFrom,
          lastDay: this.dateUntil,
        }
        this.$store.dispatch('agenda/loadDataAgendaFilterDate', data).then(res => {
          if (res) {
            if (res.length > 0) {
              const data = []
              for (let i = 0; i < res.length; i++) {
                data.push({
                  Proovedor: res[i].pvd,
                  Teléfono: res[i].tlf,
                  Dirección: res[i].dir,
                  Camión: res[i].pro,
                  Motivo: res[i].obs,
                  Inicio: res[i].start,
                  Finalización: res[i].end,
                })
              }
              functions.JSONToCSVConvertor(data, 'Lista_Eventos_' + this.dateFrom, true)
            }
          }
        })
      },
      getRandomColor () {
        var letters = '0123456789ABCDEF'
        var newPathColor = '#'
        for (var i = 0; i < 6; i++) {
          newPathColor += letters[Math.floor(Math.random() * 16)]
        }
        return newPathColor
      },
      detectMob () {
        const toMatch = [
          /Android/i,
          /webOS/i,
          /iPhone/i,
          /iPad/i,
          /iPod/i,
          /BlackBerry/i,
          /Windows Phone/i,
        ]

        return toMatch.some((toMatchItem) => {
          return navigator.userAgent.match(toMatchItem)
        })
      },
      openLink (link) {
        window.open(`${link}`, '_blank')
      },
      clearData () {
        this.time = null
        this.time2 = null
        this.scheduleReason = ''
        this.usedTruck = ''
      },
      openAppointment () {
        this.dialogAppointment = true
        this.clearData()
      },
      changeAll () {
        if (this.allAppoitment) {
          this.events = this.eventsComplete
        }
      },
      closeEvent () {
        this.banEventSelect = false
        this.dialogEvent = false
      },
      dayFunction (event) {
        if (!this.banEventSelect) {
          this.now = event.date
          this.dialogAppointment = true
          this.clearData()
        }
      },
      moreEvent (event) {
        this.start = event.date
        this.type = 'day'
      },
      cancelAppoiment () {
        this.banEventSelect = false
        let cont = 0
        let ban = true
        while (ban && cont < this.events.length) {
          if (this.events[cont] === this.selectEvent) {
            this.events.splice(cont, 1)
            ban = false
          }
          cont++
        }
        firebase.database().ref(`schedule/${this.$store.state.user.user.uid}/${this.selectEvent.id}/`).remove()
        this.dialogEvent = false
      },
      eventFunction (event) {
        this.banEventSelect = true
        this.selectEvent = event.event
        this.dialogEvent = true
      },
      currentDate () {
        this.$store.dispatch('getCurrentDay').then(res => {
          this.start = res
          this.now = res
        })
      },
      viewDay ({ date }) {
        this.start = date
        this.type = 'day'
      },
      getEventColor (event) {
        return event.color
      },
      showIntervalLabel (interval) {
        return interval.minute === 0
      },
      async addEvent () {
        if (this.$refs.formAddEvent.validate()) {
          const events = this.eventsComplete
          // const allDay = this.rnd(0, 3) === 0
          if (!this.time) {
            // this.time = '00:00'
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'Debes selecionar la hora DESDE para continuar',
            }
            return
          }
          if (!this.time2) {
            // this.time2 = '00:00'
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'Debes selecionar la hora HASTA para continuar',
            }
            return
          }
          this.loadingAgendar = true
          // setTimeout(() => {
          const data = {
            name: this.usedTruck,
            start: this.now + ' ' + this.time,
            end: this.now + ' ' + this.time2,
            color: 'primary',
            obs: this.scheduleReason,
            pro: this.usedTruck,
            tlf: this.provider.phone,
            dir: this.provider.direction,
            pvd: this.provider.completeData,
            day: this.now,
          }
          firebase.database().ref(`schedule/${this.$store.state.user.user.uid}`).push(data).then((res) => {
            this.snackbar = {
              show: true,
              color: 'green',
              text: 'La cita se guardó correctamente',
            }
            this.dialogAppointment = false
            this.loadingAgendar = false
            events.push({
              name: this.usedTruck,
              start: this.now + ' ' + this.time,
              end: this.now + ' ' + this.time2,
              color: 'primary',
              obs: this.scheduleReason,
              pro: this.usedTruck,
              id: res._delegate._path.pieces_[2],
              tlf: this.provider.phone,
              dir: this.provider.direction,
              pvd: this.provider.completeData,
              day: this.now,
            })

            this.eventsComplete = events
            this.events = this.eventsComplete
            this.allAppoitment = true
          }).catch(() => {
            this.loadingAgendar = false
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'Error al guardar la cita',
            }
          })
          this.eventsComplete = events
          // }, 200)
        }
      },
      getEvents () {
        this.events = this.$store.state.agenda.lstAgenda
        this.eventsComplete = this.$store.state.agenda.lstAgenda
      },
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },
    },
    mounted () {
      if (this.plan.diasConsulta) {
        this.minDateForConsults = moment().subtract(this.plan.diasConsulta, 'days').format('YYYY-MM-DD')
      }
    },
  }
</script>

<style>
  .v-calendar-daily__pane {
    width: 100%;
    overflow-y: hidden;
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding-top: 20px;
  }
</style>

<style scoped>
  .controls {
    position: relative;
  }
</style>
<style lang="css">
  .v-input--selection-controls .v-input__slot > .v-label, .v-input--selection-controls .v-radio > .v-label {
    min-width: 60px !important;
  }
</style>
